<template>
  <section class="card-container">
    <div class="card-content">
      <p class="title mb-[8px] card-title">發放獎項</p>
      <BaseElForm ref="formRef" :rules="formRules" :model="formData" label-position="top" testBane="reward_setting_block">
        <BaseElFormItem label="獎項類型" prop="type">
          <BaseElSelect
            v-model="formData.type"
            testName="fromData_type"
            placeholder="請選擇"
            @change="rewardTypeChange"
          >
            <BaseElSelectOption
              v-for="reward in rewardTypes"
              :key="reward.value"
              :label="reward.name"
              :value="reward.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <!-- 選擇點數 -->
        <BaseElFormItem
          v-if="formData.type === 'point'"
          label="選擇點數"
          prop="shopPointKey"
        >
          <BaseDataSelect
            :value.sync="formData.shopPointKey"
            :dataList="shopPointList"
            placeholder="請選擇點數"
            objKey="key"
          />
        </BaseElFormItem>

        <BaseElFormItem
          v-if="formData.type === 'cashback' || formData.type === 'point'"
          :label="formatAmountText(formData.type)"
          prop="awardAmount"
        >
          <BaseElInput
            v-model="formData.awardAmount"
            testName="fromData_awardAmount"
            placeholder="請輸入數字"
            type="number"
            :min="0"
            :max="defaultRangeMax"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.type === 'coupon'"
          label="票券綁定"
          prop="couponId"
        >
          <BaseElSelect
            v-model="formData.couponId"
            testName="fromData_couponId"
            popper-class="select-popper"
            placeholder="選擇票券"
            @visible-change="showCouponSelectModal = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.couponId, 'coupon')"
              :value="formData.couponId"
            />
          </BaseElSelect>
          <p v-if="formData.status ==='notExist'" class="danger">找不到先前綁定的票券，請重新綁定票券</p>
          <div v-if="showCouponExchange">
            <div v-if="formData.status === 'noStock'">
              <p class="danger">庫存數量：0</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
            <div v-if="formData.status === 'notEnoughStock'">
              <p class="primary">庫存數量：{{ couponStock(formData.couponId) }}</p>
              <p class="gray">此票券為「兌換券」，請留意兌換碼剩餘數量。相關設定請至「票券模組 > 兌換券設定 > 兌換碼管理」查看</p>
            </div>
          </div>
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.type === 'classTicket'" label="堂票綁定" prop="classTicket">
          <BaseElSelect
            v-if="useClassTicket"
            v-model="formData.classTicket"
            popper-class="select-popper"
            placeholder="選擇堂票"
            @visible-change="selector.classTicket = true"
          >
            <BaseElSelectOption
              :label="findCouponName(formData.classTicket, 'classTicket')"
              :value="formData.classTicket"
            />
          </BaseElSelect>
          <p v-else class="text-danger">未開啟堂票模組故無法顯示</p>
        </BaseElFormItem>
        <BaseElFormItem label="獎項數量" prop="availableStock">
          <div>
            <BaseElInput
              v-model="formData.availableStock"
              testName="fromData_availableStock"
              :disabled="formData.unlimitedStock"
              type="number"
              :min="0"
              :max="defaultRangeMax"
              :placeholder="`請輸入數字0~${defaultRangeMax}`"
            />
            <p v-if="showCouponExchange && (formData.status === 'noStock' || formData.status === 'notEnoughStock') && couponStock(formData.awardCouponId) < formData.lastStock" class="warning">庫存數量不足，建議補足庫存 {{ ((formData.lastStock) - couponStock(formData.awardCouponId)) || '-' }} 件 (還需送出 {{ formData.lastStock }} 份獎項)</p>

            <div>
              <BaseElCheckbox
                v-if="formData.type !== 'miss'"
                v-model="formData.showAvailableStock"
                testName="fromData_showAvailableStock"
              >
                前台顯示數量
              </BaseElCheckbox>
              <BaseElCheckbox
                v-if="formData.type !== 'miss'"
                v-model="formData.unlimitedStock"
                testName="fromData_unlimitedStock"
                @change="() => formData.availableStock = null"
              >
                無數量上限
              </BaseElCheckbox>
            </div>
          </div>
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.type !== 'miss'" label="獎項名稱" prop="name">
          <BaseElInput
            v-model="formData.name"
            testName="fromData_name"
            placeholder="請輸入名稱"
          />
        </BaseElFormItem>

        <BaseElFormItem label="圖片" prop="img">
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="imagePlaceHolder"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />

    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="resourceData.coupon"
      :typeOptions="couponTypeOptions"
      disabledExp
      @confirm="onSelectResource($event, 'coupon')"
      @close="showCouponSelectModal = false"
    />
    <!-- 堂票選擇器 -->
    <ClassTicketAdvanceSelect
      v-if="selector.classTicket"
      :data="classTicketDisplayFilter"
      :typeOptions="couponTypeConfig"
      :selectedData="formData.classTicket ? [formData.classTicket] : []"
      :disabledExp="true"
      @confirm="onSelectResource($event, 'classTicket')"
      @close="selector.classTicket = false"
    />
  </section>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, ref, reactive } from 'vue'
import { get, find, omit, filter } from 'lodash'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import ClassTicketAdvanceSelect from '@/components/Select/ClassTicketAdvanceSelect.vue'
import { GetCoupon } from '@/api/lottery/coupon'
import { GetClassTicket } from '@/api/classTicket'
import { passValue } from '@/utils/helper'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import { couponTypeConfig } from '@/config/couponExchange'
import { isDigitRules, noEmptyRules } from '@/validation'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'

export default defineComponent({
  name: 'RewardSettingBlock',
  components: {
    ImageCropper,
    UploadButton,
    CouponAdvanceSelect,
    ClassTicketAdvanceSelect,
    BaseDataSelect,
  },
  props: {
    rewardTypes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup () {
    const { shopId, shopPointList } = useShop()
    const { checkAction } = usePermissions()
    const { formData, formRef, initFormData } = useBaseForm()
    const { fetchAll } = useFetch()
    const defaultRangeMax = 9999999
    initFormData({
      img: null,
      status: undefined,
      name: undefined,
      type: undefined,
      availableStock: undefined,
      coupon: undefined,
      showAvailableStock: false,
      awardAmount: undefined,
      couponId: undefined,
      awardCouponId: undefined,
      unlimitedStock: false,
      shopPointKey: null,
    })

    const uploadDialog = ref(false)
    const showCouponSelectModal = ref(false)
    const imagePlaceHolder = ref(null)
    const resourceData = reactive({
      classTicket: [],
      coupon: [],
    })
    const selector = reactive({
      classTicket: false,
    })

    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))
    const usePospalCoupon = computed(() => checkAction('admin.couponPospal.find'))
    const useOpentixCoupon = computed(() => checkAction('admin.couponOpentix.page'))

    const useClassTicket = computed(() => checkAction('admin.classTicket.page'))

    const showCouponExchange = computed(() => {
      const item = find(resourceData.coupon, { id: formData.couponId })
      return get(item, 'type') === 'exchange'
    })
    const couponTypeOptions = computed(() => {
      const omitList = []
      if (!useCoupon.value) omitList.push('coupon')
      if (!useExchangeCoupon.value) omitList.push('exchange')
      if (!usePospalCoupon.value) omitList.push('pospal')
      if (!useOpentixCoupon.value) omitList.push('opentix')
      return omit(couponTypeConfig, omitList)
    })
    const formRules = computed(() => {
      const rules = {
        type: [{ required: true, message: '請選擇獎項類型', trigger: 'change' }],
        availableStock: [{ required: true, message: '請輸入獎項數量', trigger: 'change' }],
        name: [{ required: true, message: '請輸入獎項名稱', trigger: 'change' }],
        // img: [{ required: true, message: '請上傳圖片', trigger: 'change' }],
      }

      if (formData.unlimitedStock) {
        delete rules.availableStock
      }

      if (['coin', 'cashback', 'point'].includes(formData.type)) {
        rules.awardAmount = [noEmptyRules(), isDigitRules()]
        if (formData.type === 'point') {
          rules.shopPointKey = [{ required: true, message: '請選擇點數', trigger: 'change' }]
        }
      } else if (formData.type === 'coupon') {
        rules.couponId = [{ required: true, message: '請選擇票券', trigger: 'change' }]
      }

      return rules
    })

    const submitData = computed(() => {
      let awardId
      if (formData.type === 'coupon') awardId = formData.couponId
      if (formData.type === 'classTicket') awardId = formData.classTicket
      const data = {
        awardName: formData.name,
        unlimitedStock: formData.unlimitedStock,
        showAvailableStock: formData.showAvailableStock,
        availableStock: formData.availableStock,
        awardType: formData.type,
        awardAmount: formData.awardAmount,
        awardId,
        awardImageId: get(formData.img, 'id'),
      }
      if (data.awardType === 'point') data.shopPointKey = formData.shopPointKey
      return data
    })

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      uploadDialog.value = true
    }

    const getImage = (data) => {
      formData.img = data
      uploadDialog.value = false
    }

    const rewardTypeChange = () => {
      formData.availableStock = undefined
      formData.shopPointKey = null
    }

    const classTicketDisplayFilter = computed(() => {
      // return filter(resourceData.classTicket, i => i.isValuable && !i.isExp)
      return resourceData.classTicket
    })
    const findCouponName = (idx, type) => {
      let dataList = []
      if (type === 'coupon') dataList = passValue(resourceData.coupon)
      else if (type === 'classTicket') dataList = passValue(resourceData.classTicket)
      const coupon = dataList.find(({ id }) => id === idx)
      if (coupon) return coupon.name
      return ''
    }

    const getCouponResources = async () => {
      const couponData = {
        coupon: [],
        exchange: [],
        pospal: [],
        opentix: [],
      }
      const callList = []
      if (useCoupon.value) {
        callList.push(async () =>
          await fetchAll(GetCoupon, {
            shopId: shopId.value,
            type: 'coupon',
          }, (res) => {
            couponData.coupon = res
          }),
        )
      }
      if (useExchangeCoupon.value) {
        callList.push(async () =>
          await fetchAll(GetCoupon, {
            shopId: shopId.value,
            type: 'exchange',
          }, (res) => {
            couponData.exchange = res
          }),
        )
      }
      if (usePospalCoupon.value) {
        callList.push(async () =>
          await fetchAll(GetCoupon, {
            shopId: shopId.value,
            type: 'pospal',
          }, (res) => {
            couponData.pospal = res
          }),
        )
      }
      if (useOpentixCoupon.value) {
        callList.push(async () =>
          await fetchAll(GetCoupon, {
            shopId: shopId.value,
            type: 'opentix',
          }, (res) => {
            couponData.opentix = res
          }),
        )
      }

      await Promise.all(callList.map((call) => call()))

      resourceData.coupon = [
        ...couponData.coupon,
        ...couponData.exchange,
        ...couponData.pospal,
        ...couponData.opentix,
      ]
    }

    const onSelectResource = (data, type) => {
      if (!data.length) return
      if (type === 'coupon') {
        formData.couponId = data[0]
        formData.status = 'notEnoughStock'
        // formData.awardCouponId = formData.couponId
      } else if (type === 'classTicket') {
        formData.classTicket = data[0]
      }
    }

    const getClassTicketRescources = async () => {
      const callList = []
      if (useClassTicket.value) {
        callList.push(async () => {
          await fetchAll(GetClassTicket, { shopId: shopId.value }, (res) => {
            resourceData.classTicket = res
          })
        })
      }

      await Promise.all(callList.map((call) => call()))
    }
    const couponStock = (couponId) => {
      const item = find(resourceData.coupon, { id: couponId })
      return get(item, 'sentLimit') - get(item, 'sentCount')
    }

    const formatAmountText = (type) => {
      const config = {
        point: '點數數量',
        cashback: '回饋金',
      }
      return config[type]
    }

    onBeforeMount(async () => {
      await Promise.all([
        getCouponResources(),
        getClassTicketRescources(),
      ])
    })

    return {
      formRef,
      formData,
      defaultRangeMax,
      loadImg,
      uploadDialog,
      getImage,
      formRules,
      rewardTypeChange,
      imagePlaceHolder,
      submitData,
      findCouponName,
      showCouponSelectModal,
      showCouponExchange,
      couponTypeConfig,
      couponTypeOptions,
      onSelectResource,
      couponStock,
      formatAmountText,
      shopPointList,
      useClassTicket,
      selector,
      classTicketDisplayFilter,
      resourceData,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
